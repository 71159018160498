@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');

.modal-dialog {
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  max-width: 638px;
  background-color: white;
  height: 230px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0A163A;
}

.modal-container .modal-content {
  width: 92%;
}

.modal-dialog .bg-warning {
  display: flex;
  flex-direction: column;
}

.modal-dialog .bg-danger {
  display: flex;
  flex-direction: column;
}

.bg-warning svg {
  margin: auto;
}
.bg-danger svg {
  margin: auto;
}

.bg-warning svg path {
  fill: #0A163A;
}

.modal-dialog button {
  margin: auto;
  margin-top: 24px;
  display: block;

  background-color: #031672;
  font-weight: 1000;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  transition: 0.2s;
}

button:hover {
  transform: scale(1.1);
}

html,
body {
  height: 100%;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

@media (min-width: 1200px) {
  #root {
    height: 100%;
    min-height: 100%;
  }
}
